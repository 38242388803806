.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    /* border-color: #a5d24a; */
}

.react-tagsinput-tag {
    background: #ebf0f3;
    border-radius: 2px;
    border: none !important;
    color: #273113;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px !important;
    margin-right: 2px;
    margin-top: 4px;
    margin-left: 5px;
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: ' ×';
    margin-left: 10px;
}

.react-tagsinput-input {
    background: transparent;
    flex: 1;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px !important;
    margin-top: 4px !important;
    outline: none;
    padding: 5px;
    width: auto;
    position: relative;
}

.tagsInput {
    border: 1px solid #ced4da;
}

/* .css-15k3avv{
max-height: 220px;
overflow: hidden;
} */
