.react-hint {
    padding: 3px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: 0.5s fadeIn;
}

.react-hint__content {
    padding: 6px;
    border-radius: 5px;
    background: #616161;
    color: #fff;
    font-size: 10px;
}

.react-hint::after {
    content: '';
    width: 0;
    height: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid transparent;
}

.react-hint--top::after {
    top: auto;
    border-bottom: none;
    border-top-color: #616161;
}

.react-hint--left::after {
    left: auto;
    border-right: none;
    border-left-color: #616161;
}

.react-hint--right::after {
    right: auto;
    border-left: none;
    border-right-color: #616161;
}

.react-hint--bottom::after {
    bottom: auto;
    border-top: none;
    border-bottom-color: #616161;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
