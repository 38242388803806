.container {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 !important;
    width: 100%;
}
.container::-webkit-scrollbar {
    width: 10px;
}
.container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
}
.container::-webkit-scrollbar-thumb {
    background-color: #878787;
    outline: 1px solid slategrey;
    border-radius: 20px;
}
.container::-webkit-scrollbar-thumb:hover {
    background-color: #7b7a7a;
}
