.blink {
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 0.7s steps(5, start) infinite;
}
@keyframes blink {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
}
.css-15k3avv {
    overflow: hidden;
    max-height: 200px;
}
.downloadSnackBarAnimation {
    animation: downn 0.5s;
    -webkit-animation: downn 0.5s;
}
@keyframes downn {
    0% {
        transform: translate(-50%, -100%);
    }
    100% {
        transform: translate(-50%, 0%);
    }
}
@-webkit-keyframes downn {
    0% {
        transform: translate(-50%, -100%);
    }
    100% {
        transform: translate(-50%, 0%);
    }
}

.walkme-custom-icon-outer-div {
    z-index: 1300 !important;
}
