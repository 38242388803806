.react-autosuggest__container {
    position: relative;
    width: '100%';
}

.react-autosuggest__input {
    width: 100%;
    height: 31.5px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #bac4ce;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #f3f3f3;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 28px;
    width: 100%;
    border: 1px solid #bac4ce;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0;
    max-height: 100px;
    overflow-y: auto;
    padding: 0;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
.ReactVirtualized__Grid__innerScrollContainer{
    min-height: 100% !important;
    position: initial !important;
}

.ReactVirtualized__Grid:focus{
    outline: none !important;
}