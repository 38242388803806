.scrollContainer {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}
.scrollContainer::-webkit-scrollbar {
    width: 10px;
}
.scrollContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
}
.scrollContainer::-webkit-scrollbar-thumb {
    background-color: #8b8b8bc7;
    outline: 1px solid slategrey;
    border-radius: 20px;
}
.scrollContainer::-webkit-scrollbar-thumb:hover {
    background-color: #7b7a7a;
}
